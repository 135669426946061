import React, {useEffect} from "react";
import {graphql, navigate, PageProps} from "gatsby"
import Container from "@mui/material/Container"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

import {getVerifyEmail} from "services/request";
import Logo from "assets/icons/logo.svg";

export default function WelcomePage({params}: PageProps) {
  const theme = useTheme();
  const {token} = params;

  useEffect(() => {
    getVerifyEmail(
      token,
      (_rsp: any) => window.location.href = window.location.origin + "/apps/mail-verified",
      (_rsp: any) => navigate("/404"),
    );
  }, [token]);

  return (
    <Container maxWidth="md">
      <div style={{marginLeft: "2rem"}}>
        <Box sx={{color: theme.palette.primary.main, p: 2}}>
          <Logo width={150}/>
        </Box>
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            sx={{marginBottom: 20, marginTop: 20}}
          >
            <CircularProgress size={200} />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

