interface ITokensInterface {
  accessToken: string | null;
  refreshToken: string | null;
}

const Tokens = {
  setTokens: (access: string, refresh: string) => {
    localStorage.setItem("accessToken", access);
    localStorage.setItem("refreshToken", refresh);
  },

  getTokens: (): ITokensInterface => {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken")
    };
  },

  clearTokens: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  },

  isLoggedIn: (): boolean => {
    const token = localStorage.getItem("accessToken");
    return token !== null;
  }
};

export default Tokens;
