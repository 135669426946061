import axios from "axios";
import Tokens from "./tokens";

export const REACT_APP_BACKEND_URI = process.env.GATSBY_APP_BACKEND_URI;
export const REACT_APP_IMAGE_URI = process.env.GATSBY_APP_IMAGE_URI;
export const API_KEY = process.env.GATSBY_APP_API_KEY;

axios.defaults.baseURL = REACT_APP_BACKEND_URI;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.timeout = 2000;

function request() {
  const { accessToken } = Tokens.getTokens();
  let instance = axios.create({
    baseURL: REACT_APP_BACKEND_URI,
    headers: {
      Authorization: "Bearer " + accessToken
    }
  });
  return instance;
}

function refreshToken(success: Function, failure: Function) {
  const { refreshToken } = Tokens.getTokens();
  const refreshTokenPath = "/auth/refresh";

  axios
    .post(refreshTokenPath, {
      authentication: { refresh_token: refreshToken }
    })
    .then(({ data }: any) => {
      Tokens.setTokens(data.auth_token, data.refresh_token);
      success();
    })
    .catch(data => {
      Tokens.clearTokens();
      window.location.href = "/login";
    });
}

function handleFailure(
  data: any,
  retry: boolean,
  success: Function,
  failure: Function
) {
  if (retry) {
    failure(data);
  } else {
    if (data.response && data.response.status === 401) {
      refreshToken(success, failure);
    } else {
      failure(data);
    }
  }
}

export async function requestGet(
  url: string,
  params: Object,
  success: Function,
  failure: Function,
  retry: boolean = false
) {
  request()
    .get(url, { params: params })
    .then(data => success(data))
    .catch(data =>
      handleFailure(
        data,
        retry,
        () => requestGet(url, params, success, failure, true),
        failure
      )
    );
}

export async function requestPost(
  url: string,
  params: string,
  success: Function,
  failure: Function,
  retry: boolean = false
) {
  request()
    .post(url, params)
    .then(data => success(data))
    .catch(data =>
      handleFailure(
        data,
        retry,
        () => requestPost(url, params, success, failure, true),
        failure
      )
    );
}

export async function requestDelete(
  url: string,
  params: Object,
  success: Function,
  failure: Function,
  retry: boolean = false
) {
  request()
    .delete(url, { params: params })
    .then(data => success(data))
    .catch(data =>
      handleFailure(
        data,
        retry,
        () => requestDelete(url, params, success, failure, true),
        failure
      )
    );
}

export async function requestPut(
  url: string,
  params: object,
  success: Function,
  failure: Function,
  retry: boolean = false
) {
  request()
    .put(url, params)
    .then(data => success(data))
    .catch(data =>
      handleFailure(
        data,
        retry,
        () => requestPut(url, params, success, failure, true),
        failure
      )
    );
}

export async function getVerifyEmail(
  verifyToken: string,
  success: Function,
  failure: Function
) {
  request()
    .get(`patients/verify_email?token=${verifyToken}&api_key=${API_KEY}`)
    .then(data => success(data))
    .catch(data => failure(data));
}
